import request from '../utils/request'
import baseUrl from './baseUrl'

export const userCode = baseUrl + '/validateCodeServlet'

export const userLogin = (data) => request({
  url: baseUrl + '/user/userLogin',
  method: 'POST',
  data
})
export const vCode = () => request({
  url: baseUrl + '/validateCodeServlet',
  method: 'GET'
})
export const UpdateUserPassword = (data) => request({
  url: baseUrl + '/user/userUpdateUserPassword',
  method: 'POST',
  data
})
//退出登录 /user/userLogOut
export const loginOut = () => request({
  url: baseUrl + '/user/userLogOut',
  method: "POST"
})
//提示语 /user/selectTimeRegard
export const loginMessage = () => request({
  url: baseUrl + '/user/selectTimeRegard',
  method: 'POST'
})