import { render, staticRenderFns } from "./UpdateUserPassword.vue?vue&type=template&id=671f914c&scoped=true&"
import script from "./UpdateUserPassword.vue?vue&type=script&lang=js&"
export * from "./UpdateUserPassword.vue?vue&type=script&lang=js&"
import style0 from "./UpdateUserPassword.vue?vue&type=style&index=0&id=671f914c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "671f914c",
  null
  
)

export default component.exports