<template>
  <div class="UpdateUserPassword">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>修改密码</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form
      :model="ruleForm"
      status-icon
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="原始密码" prop="oldPsw">
        <el-input
          v-model="ruleForm.oldPsw"
          type="password"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="新的密码" prop="pass">
        <el-input
          type="password"
          v-model="ruleForm.pass"
          autocomplete="off"
          placeholder="6 到 20 位的数字、字母组合"
        ></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="checkPass">
        <el-input
          type="password"
          v-model="ruleForm.checkPass"
          autocomplete="off"
          placeholder="6 到 20 位的数字、字母组合"
        ></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >提交</el-button
        >
        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { UpdateUserPassword } from "../../api/login";
import sha1 from "sha1";
export default {
  name: "UpdateUserPassword",
  data() {
    var checkoldPsw = (rule, value, callback) => {
      if (value === "") {
        return callback(new Error("请输入原始密码"));
      } else {
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      var psw = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/;
      if (value === "") {
        callback(new Error("请输入密码"));
      } else if (value === this.ruleForm.oldPsw) {
        callback(new Error("不能和原始密码一致"));
      } else if (!psw.test(value)) {
        callback(new Error("请输入6 到 20 位的数字、字母组合"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        pass: "",
        checkPass: "",
        oldPsw: "",
      },
      rules: {
        pass: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
        oldPsw: [{ validator: checkoldPsw, trigger: "blur" }],
      },
    };
  },
  methods: {
    submitForm(formName) {
      var data = {
        oldPassword: sha1(this.ruleForm.oldPsw),
        newPassword: sha1(this.ruleForm.pass),
      };
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { data } = await UpdateUserPassword(data);
          console.log(data);
          if (data.code !== 0) {
            this.$message({
              message: "修改密码失败",
              type: "warning",
            });
          } else {
            this.$message({
              message: "修改密码成功，请重新登录",
              type: "success",
            });
            this.$router.push("/");
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
<style lang="less" scoped>
.UpdateUserPassword {
  .el-breadcrumb {
    font-size: 18px;
  }
  .el-form {
    margin-top: 30px;
    .el-input {
      width: 500px;
      overflow: hidden;
    }
  }
}
</style>